import { Button as MuiButton, ButtonProps, useTheme } from '@mui/material'

type Props = ButtonProps & {
  label: React.ReactNode
  rounded?: boolean
  loadingIcon?: any
  isLoading?: boolean
}

function Button({ label, rounded, isLoading = false, loadingIcon, ...rest }: Props) {
  const theme = useTheme()

  return (
    <MuiButton
      {...rest}
      sx={{
        ...(rest.sx ?? {}),
        ':hover': {
          ...((rest.sx ?? {}) as unknown as any)[':hover'],
          boxShadow: 'none',
        },
        minWidth: (rest.sx as any)?.minWidth ? (rest.sx as any).minWidth : 40.5,
        fontWeight: '600',
        textTransform: 'none',
        borderRadius: rounded ? 9999 : 0.5,
        boxShadow: 'none',
        ...(rest.disabled
          ? { bgcolor: `${theme.palette.common.white} !important`, '& > svg path': { fill: theme.palette.common.gray600 } }
          : {}),
      }}
    >
      {!isLoading ? label : loadingIcon}
    </MuiButton>
  )
}

export { Button }
