import { clearCart, openCartDrawer, setCartFromApi, setIsLoading } from './cartSlice'

import { AppThunk } from '../../rootStore'
import { addRequest, removeRequest } from '../../LoadingStore/loadingSlice'
import { Requests } from '../../../types/common'
import { TaxExempt } from '../../../types/checkout'
import { B2BCheckoutRepository as CheckoutRepository } from '../../../repositories/b2b/CheckoutRepository'
import { resetTaxExemptSubmissionResp, updateTaxExemptSubmissionResp } from '../CheckoutStore/checkoutSlice'
import { B2BCartRepository } from '../../../repositories/b2b/CartRepository'
import { setMinQtyModal } from '../../ProductsStore/productsSlice'

export const submitTaxExemptRequest =
  (taxForm: TaxExempt): AppThunk =>
  async (dispatch) => {
    try {
      resetTaxExemptSubmissionResp()
      const checkoutRepository = CheckoutRepository.getInstance()

      dispatch(addRequest(Requests.submitTaxExempt))

      await checkoutRepository.submitTaxExempt(taxForm)
      dispatch(updateTaxExemptSubmissionResp('success'))
    } catch (err) {
      dispatch(updateTaxExemptSubmissionResp('error'))
      console.log('[submitTaxExempt.error]', err)
    } finally {
      dispatch(removeRequest(Requests.submitTaxExempt))
    }
  }

export const getCart = (): AppThunk => async (dispatch, getState) => {
  try {
    const { b2bCartReducer, authReducer } = getState()
    let cartId = b2bCartReducer.cartId
    console.log('cartId', cartId)
    const userId = authReducer?.user?.userId
    if (!cartId) {
      const users = JSON.parse(localStorage.getItem('userCart') ?? '[]')
      const userExists = users.some((obj: { userId: string; cartId: string }) => obj?.userId === userId)
      console.log('userExists', userExists)
      if (userExists) {
        const userIndex = users.findIndex((obj: { userId: string; cartId: string }) => obj.userId === userId)
        cartId = users[userIndex]?.cartId
      }
    }

    if (!cartId) {
      // if there is not cartId, it means that we don't have any
      // cart configured, we can skip making this API call
      return
    }

    dispatch(addRequest(Requests.getCart))

    const cartRepository = B2BCartRepository.getInstance()

    const cart = await cartRepository.getCart(cartId)

    dispatch(setCartFromApi(cart))
  } catch (err) {
    console.log('[getCart.error]', err)
    dispatch(clearCart())
  } finally {
    dispatch(removeRequest(Requests.getCart))
  }
}

export const storeUserCart = (): AppThunk => async (dispatch, getState) => {
  const { b2bCartReducer, authReducer } = getState()
  const cartId = b2bCartReducer.cartId
  const userId = authReducer?.user?.userId
  const users = JSON.parse(localStorage.getItem('userCart') ?? '[]')
  const userExists = users.some((obj: { userId: string; cartId: string }) => obj?.userId === userId)
  if (userExists) {
    const userIndex = users.findIndex((obj: { userId: string; cartId: string }) => obj.userId === userId)
    users.splice(userIndex, 1)
  }
  if (cartId) users.push({ userId: userId, cartId: b2bCartReducer.cartId })
  localStorage.setItem('userCart', JSON.stringify(users))
}

export const addToCart =
  (product: { sku: string; unitOfMeasure: string; quantity: number; variantId?: number }): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { b2bCartReducer, authReducer } = getState()
      const cartId = b2bCartReducer.cartId
      const userId = authReducer?.user?.userId
      dispatch(addRequest(Requests.addToCart))
      dispatch(setIsLoading({ isLoading: true, sku: product.sku }))
      const cartRepository = B2BCartRepository.getInstance()

      const cart = await cartRepository.addToCart(product, cartId)
      if (!cartId) {
        const users = JSON.parse(localStorage.getItem('userCart') ?? '[]')
        const userExists = users.some((obj: { userId: string; cartId: string }) => obj?.userId === userId)
        if (userExists) {
          const userIndex = users.findIndex((obj: { userId: string; cartId: string }) => obj.userId === userId)
          users.splice(userIndex, 1)
        }
        if (cart?.id) users.push({ userId: userId, cartId: cart?.id })
        localStorage.setItem('userCart', JSON.stringify(users))
      }
      dispatch(setCartFromApi(cart))
      dispatch(openCartDrawer())
    } catch (err) {
      console.log('[addToCart.error]', err)
      const errorMessage = (<any>err)?.response?.data
      if (errorMessage) {
        dispatch(
          setMinQtyModal({
            message: errorMessage,
          })
        )
      }
    } finally {
      dispatch(setIsLoading({ isLoading: false, sku: '' }))
      dispatch(removeRequest(Requests.addToCart))
    }
  }

export const removeFromCart =
  (itemId: number | string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { b2bCartReducer, authReducer } = getState()
      const cartId = b2bCartReducer.cartId
      const userId = authReducer?.user?.userId
      if (!cartId) {
        // if we don't have a cartId, we won't be able to update any cart items
        return
      }
      dispatch(addRequest(Requests.removeFromCart))
      dispatch(setIsLoading({ isLoading: true, sku: itemId.toString() }))

      const cartRepository = B2BCartRepository.getInstance()

      const cart = await cartRepository.removeFromCart(cartId, itemId)

      if (!cart) {
        const users = JSON.parse(localStorage.getItem('userCart') ?? '[]')
        const userExists = users.some((obj: { userId: string; cartId: string }) => obj?.userId === userId)
        if (userExists) {
          const userIndex = users.findIndex((obj: { userId: string; cartId: string }) => obj.userId === userId)
          users.splice(userIndex, 1)
          localStorage.setItem('userCart', JSON.stringify(users))
        }
      }
      dispatch(setCartFromApi(cart))
    } catch (err) {
      console.log('[removeFromCart.error]', err)
    } finally {
      dispatch(setIsLoading({ isLoading: false, sku: '' }))
      dispatch(removeRequest(Requests.removeFromCart))
    }
  }

export const updateCartItem =
  (product: { itemId: number | string; sku: string; unitOfMeasure: string; quantity: number; variantId?: number }): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { b2bCartReducer } = getState()
      const cartId = b2bCartReducer.cartId
      if (!cartId) {
        // if we don't have a cartId, we won't be able to update any cart items
        return
      }
      dispatch(addRequest(Requests.updateCartItem))

      dispatch(setIsLoading({ isLoading: true, sku: product.itemId.toString() }))
      const cartRepository = B2BCartRepository.getInstance()

      const cart = await cartRepository.updateCartItem(cartId, product)

      dispatch(setCartFromApi(cart))
    } catch (err) {
      console.log('[updateCartItem.error]', err)
    } finally {
      dispatch(setIsLoading({ isLoading: false, sku: '' }))
      dispatch(removeRequest(Requests.updateCartItem))
    }
  }
