import { useEffect, useCallback, lazy } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { ThemeProvider, CssBaseline, StyledEngineProvider } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useMsal } from '@azure/msal-react'
// import Cookies from 'js-cookie'

import { ErrorBoundary } from './error/ErrorBoundary'
// import Routes from './routes'
import Loadable from './Loadable'

import themes from '../theme'
// import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks'
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks'
import { selectUiState } from '../stores/UiStore/selectors'
import NavigationScroll from '../layout/NavigationScroll'
// project imports
import MainLayout from '../layout/MainLayout'
import MinimalLayout from '../layout/MinimalLayout'
import { trackPage } from '../utils/segment'
import { isCrossBrandUser, isQorpak, isValidJwt } from '../utils/common'
import { deleteCookie } from '../utils/cookie'
import { selectUser } from '../stores/AuthStore/selectors'
import { logoutRequest } from '../authConfig'
import { setIsCrossBrandUser } from '../stores/UserStore/userSlice'

// dashboard routing
const DashboardHome = Loadable(lazy(() => import('../pages/dashboard/Home')))
const DashboardV2 = Loadable(lazy(() => import('../pages/dashboard-v2')))

// products routing
// const ProductCatalog = Loadable(lazy(() => import('./pages/products/ProductCatalog')))
// const BrowseProducts = Loadable(lazy(() => import('./pages/products/BrowseProducts')))
const ProductList = Loadable(lazy(() => import('../pages/products/ProductList')))
const ProductDetailLite = Loadable(lazy(() => import('../pages/products/ProductDetailLite')))

// B2B cart/checkout routing
const B2BCart = Loadable(lazy(() => import('../pages/b2b/cart/Cart')))
const B2BCheckout = Loadable(lazy(() => import('../pages/b2b/checkout/Checkout')))
const B2BOrderConfirmation = Loadable(lazy(() => import('../pages/b2b/checkout/OrderConfirmation')))

// B2C cart/checkout routing - NOT IN USE
// const B2CCart = Loadable(lazy(() => import('../pages/b2c/cart/Cart')))
// const B2CCheckout = Loadable(lazy(() => import('../pages/b2c/checkout/Checkout')))
// const B2COrderConfirmation = Loadable(lazy(() => import('../pages/b2c/checkout/OrderConfirmation')))

// resources routing
const MyOrders = Loadable(lazy(() => import('../pages/resources/MyOrders')))
const OrderDetail = Loadable(lazy(() => import('../pages/resources/OrderDetail')))

// settings routing
// const Settings = Loadable(lazy(() => import('./pages/system/Settings')))
// const TeamSettings = Loadable(lazy(() => import('./pages/system/TeamSettings')))

// auth routing
const Onboarding = Loadable(lazy(() => import('../pages/auth/Onboarding')))

// New Item Setup App
const NewItemApp = Loadable(lazy(() => import('../pages/new-item-app')))
const NewItemSearch = Loadable(lazy(() => import('../pages/new-item-app/search')))
const NewItemAppFramework = Loadable(lazy(() => import('../pages/new-item-app/FrameWork')))
const NewItemAppItemReview = Loadable(lazy(() => import('../pages/new-item-app/item-review')))

// Logout
const CustomerNewUser = Loadable(lazy(() => import('../components/CustomerNewUser')))

function AuthedContent() {
  const customization = useAppSelector(selectUiState)
  const location = useLocation()
  const { instance } = useMsal()
  const isAuthed = isValidJwt()
  const user = useAppSelector(selectUser)
  const dispatch = useAppDispatch()
  const isProductDetailEnabled =
    user?.userRoles?.includes('Customer Catalog Image Update') ||
    user?.userRoles?.includes('Customer B2B Catalog') ||
    user?.userRoles?.includes('Customer B2B Checkout')
  const isB2BCheckoutEnabled = user?.userRoles?.includes('Customer B2B Checkout')

  const handleLogout = useCallback(() => {
    deleteCookie(`x-ms-cpim-sso:${process.env.REACT_APP_B2C_TENANT_DOMAIN ?? 'bpkgomnitest.onmicrosoft.com'}_0`)
    deleteCookie(`x-ms-cpim-sso:${process.env.REACT_APP_B2C_TENANT_DOMAIN_QORPAK ?? 'bpkgomnitest.onmicrosoft.com'}_0`)
    instance.logoutRedirect(logoutRequest)
  }, [instance, location])

  useEffect(() => {
    trackPage()
  }, [location])

  useEffect(() => {
    if (!isAuthed) {
      handleLogout()
    }

    if (isAuthed) {
      dispatch(setIsCrossBrandUser(isCrossBrandUser()))
    }
  }, [isAuthed, handleLogout])

  if (!isAuthed) {
    return null
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ErrorBoundary catchErrors="always">
            <NavigationScroll>
              <Routes>
                <Route path="/" element={<MainLayout />}>
                  <Route path="/employee/new-item-app" element={<NewItemApp />} />
                  <Route path="/employee/new-item-app/search" element={<NewItemSearch />} />
                  <Route path="/employee/new-item-app/framework" element={<NewItemAppFramework />} />
                  <Route path="/employee/new-item-app/item-review/:itemId" element={<NewItemAppItemReview />} />
                  {/* <Route element={isProductDetailEnabled}> */}

                  {isQorpak() ? (
                    <Route path="/dashboard/default" element={<DashboardHome />} />
                  ) : (
                    <Route path="/dashboard/default" element={<DashboardV2 />} />
                  )}
                  {isProductDetailEnabled && (
                    <>
                      <Route path="/products/list" element={<ProductList />} />
                      <Route path="/products/product/:productId" element={<ProductDetailLite />} />
                      <Route path="/products/productLite/:productId" element={<ProductDetailLite />} />
                    </>
                  )}
                  {/* </Route> */}

                  {isB2BCheckoutEnabled && isProductDetailEnabled && (
                    <>
                      {/* B2B Routes */}
                      <Route path="cart" element={<B2BCart />} />
                      <Route path="order-confirmation" element={<B2BOrderConfirmation />} />
                      <Route path="checkout" element={<B2BCheckout />} />
                      <Route path="checkout/:state" element={<B2BCheckout />} />
                    </>
                  )}
                  {/* B2C Routes - NOT IN USE */}
                  {/* <Route path="cart" element={<B2CCart />} />
                          <Route path="order-confirmation" element={<B2COrderConfirmation />} />
                          <Route path="checkout" element={<B2CCheckout />} />
                          <Route path="checkout/:state" element={<B2CCheckout />} /> */}
                  {/* 
                  <Route path="/products/catalog" element={<ProductCatalog />} />
                  <Route path="/products/browse" element={<BrowseProducts />} /> */}
                  <Route path="/resources/my-orders" element={<MyOrders />} />
                  <Route path="/resources/orders/:orderType" element={<MyOrders />} />
                  <Route path="/resources/order/:orderId" element={<OrderDetail />} />
                  <Route path="/newuser" element={<CustomerNewUser />} />
                  <Route path="/orders" element={<DashboardHome />} />

                  {isQorpak() ? <Route path="/" element={<DashboardHome />} /> : <Route path="/" element={<DashboardV2 />} />}
                </Route>
                <Route path="/" element={<MinimalLayout />}>
                  <Route path="/onboarding" element={<Onboarding />} />
                </Route>
              </Routes>
            </NavigationScroll>
          </ErrorBoundary>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default AuthedContent
