import { Stack, Box, useTheme, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Drawer, Typography, Button } from '../../../common'
import { B2BCartItem } from '../CartItem'
import { CloseIcon } from '../../../icons'

import './../../../../i18n'
import { useAppSelector } from '../../../../hooks/reduxHooks'
import { selectCartItems } from '../../../../stores/b2b/CartStore/selectors'
import { trackActionClicked, trackViewCart } from '../../../../utils/segment'

type Props = {
  open: boolean
  onToggle: () => void
}

const B2BCartDrawer = ({ open, onToggle }: Props) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const cartItems = useAppSelector(selectCartItems)

  return (
    <Drawer open={open} onToggle={onToggle}>
      <Box width={{ xs: '100%', sm: 450 }} height="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={3}
          sx={{
            position: 'sticky',
            top: 0,
            pt: 4,
            pb: 2,
            zIndex: 10,
            backgroundColor: theme.palette.common.white,
          }}
        >
          <Typography variant="h2">{t('drawer.cart.title')}</Typography>
          <Button label={<CloseIcon fill={theme.palette.common.black} />} size="small" sx={{ minWidth: 22 }} onClick={onToggle} />
        </Stack>
        {cartItems && cartItems.length > 0 ? (
          // product list
          <>
            <Box pb={30}>
              <Box mx={2}>
                {cartItems.length > 0 &&
                  cartItems.map((item) => (
                    <Grid key={item.id} item xs={12} mx={2} mb={2}>
                      <B2BCartItem data={item} size="small" parent="Cart Drawer" />
                      {/* {i < cartItems.length - 1 && <Divider />} */}
                    </Grid>
                  ))}
              </Box>
            </Box>
          </>
        ) : (
          // empty state
          <Box mx={2} pb={30} height="90%">
            <Stack spacing={3} height="100%" justifyContent="center" alignItems="center">
              <img alt="Empty cart" src="/images/empty_cart.png" />
              <Typography sx={{ fontSize: 16, textAlign: 'center', px: 5 }}>{t('cart.empty_cart')}</Typography>
            </Stack>
          </Box>
        )}
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{
            position: 'fixed',
            bottom: 0,
            p: 3,
            width: 'inherit',
            zIndex: 10,
            backgroundColor: theme.palette.common.white,
          }}
        >
          <Button
            label={t('drawer.cart.buttons.view_cart_and_checkout')}
            variant="contained"
            rounded={true}
            sx={{
              width: '100%',
              py: 1.5,
            }}
            onClick={() => {
              onToggle()
              navigate('/cart')

              // Segment tracking
              trackViewCart('Drawer', cartItems ?? [])
            }}
          />
          {cartItems && cartItems.length > 0 ? (
            <Button
              label={t('drawer.cart.buttons.continue_shopping')}
              variant="outlined"
              rounded={true}
              sx={{
                width: '100%',
                py: 1.5,
              }}
              onClick={() => {
                onToggle()
                navigate('/products/list')

                // Segment tracking
                trackActionClicked('Continue Shopping')
              }}
            />
          ) : (
            <Button
              label={t('drawer.cart.buttons.start_shopping')}
              variant="outlined"
              rounded={true}
              sx={{
                width: '100%',
                py: 1.5,
              }}
              onClick={() => {
                onToggle()
                navigate('/products/list')

                // Segment tracking
                trackActionClicked('Start Shopping')
              }}
            />
          )}
        </Stack>
      </Box>
    </Drawer>
  )
}

export { B2BCartDrawer }
