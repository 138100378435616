import React, { useEffect, useState } from 'react'
import { Grid, Stack, TextField, useTheme } from '@mui/material'

import { Typography, Button } from '../../../common'
import { CrossIcon } from '../../../icons'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks'
import { removeFromCart, updateCartItem } from '../../../../stores/b2b/CartStore/actions'
import { PhysicalItem } from '../../../../types/cart'
import { CheckoutPhysicalItem, OrderConfirmationLineItem } from '../../../../types/checkout'
import { trackRemoveProductFromCart } from '../../../../utils/segment'
import { selectProductCategoryMap } from '../../../../stores/b2b/CartStore/selectors'
import { removeFromProductCategoryMap } from '../../../../stores/b2b/CartStore/cartSlice'
import { getImageUrl } from '../../../../utils/common'

type Props = {
  data: PhysicalItem | CheckoutPhysicalItem | OrderConfirmationLineItem
  size: 'small' | 'default'
  parent: string // for Segment to track cart operations' source
  editDisabled?: boolean
  ordered?: boolean
  readonly?: boolean
}

const B2BCartItem = React.memo(({ data, size, editDisabled, ordered, parent, readonly }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const productCategoryMap = useAppSelector(selectProductCategoryMap)

  const [quantity, setQuantity] = useState(data.quantity)
  const [uom, setUom] = useState('')

  useEffect(() => {
    if ('soldAs' in data && !!data.soldAs.name) {
      setUom(data.soldAs.name)
    } else if ('unitOfMeasure' in data && !!data.unitOfMeasure.name) {
      setUom(data.unitOfMeasure.name)
    } else {
      setUom('TBD')
    }
  }, [data])

  const remove = () => {
    if (!ordered && 'id' in data) {
      dispatch(removeFromCart(data.id))

      // Segment tracking
      trackRemoveProductFromCart(data, parent, productCategoryMap?.[data.sku] ?? '')
      dispatch(removeFromProductCategoryMap({ sku: data.sku }))
    }
  }

  useEffect(() => {
    setQuantity(data.quantity)
  }, [data])

  useEffect(() => {
    if (data.quantity === quantity) {
      // skip sending data if API and Client are already in sync
      return
    }
    // add debounce here to prevent triggering the API call on every keystroke
    const timeoutId = setTimeout(() => {
      if (!ordered && 'id' in data) {
        dispatch(
          updateCartItem({
            itemId: data.id,
            sku: data.sku,
            unitOfMeasure: data.soldAs.identifier ?? '',
            quantity: quantity,
            variantId: data.variantId,
          })
        )
      }
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [quantity])

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const qty = +evt.target.value
    setQuantity(qty)
  }

  const thumbnail = getImageUrl(data.imageUrl, 100, true)

  return (
    <Grid container spacing={2} p={3}>
      <Grid item xs={size === 'small' ? 3 : 2}>
        <img
          src={thumbnail}
          alt={data.name}
          style={{ width: '100%', maxHeight: '150px', objectFit: 'contain', height: '100%', objectPosition: 'center top' }}
        />
      </Grid>
      <Grid container item xs={size === 'small' ? 9 : 10} spacing={1}>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <Stack>
              <Typography variant="h4" sx={{ fontSize: 18 }}>
                {data.name}
              </Typography>
              <Typography sx={{ fontSize: 16 }}>
                {t('product.berlin_item')}
                {data.sku}
              </Typography>
              {data.alias ? (
                <Typography sx={{ fontSize: 16 }}>
                  {t('product.your_item')}
                  {data.alias}
                </Typography>
              ) : (
                ''
              )}
            </Stack>
          </Stack>
        </Grid>

        {!ordered && (
          <Grid item xs={12} display="flex">
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={4}>
                {!readonly && (
                  <TextField
                    fullWidth={true}
                    id="outlined-basic"
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 1 } }}
                    variant="standard"
                    name="quantity"
                    value={quantity}
                    onChange={handleChange}
                    disabled={editDisabled}
                  />
                )}
              </Grid>
              <Grid item xs={8} alignItems="center">
                <Stack spacing={1} direction="row" justifyContent={size === 'default' ? 'flex-start' : 'flex-end'} alignItems="center">
                  {!editDisabled && !readonly && (
                    <Button
                      label={
                        <>
                          {size === 'default' && <CrossIcon stroke={theme.palette.common.darkBlue}></CrossIcon>}
                          {t('cart.product_card.buttons.remove')}
                        </>
                      }
                      variant="text"
                      size="small"
                      sx={{ color: theme.palette.common.darkBlue, fontSize: 12, fontWeight: 'normal' }}
                      onClick={remove}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack display={size === 'small' ? 'none' : 'flex'}>
            {(ordered || readonly) && (
              <Typography sx={{ fontSize: 16 }}>
                {t('cart.product_card.quantity')}: {quantity}
              </Typography>
            )}
            <Typography sx={{ fontSize: 16 }}>
              {t('cart.product_card.sold_as')}: {uom}
            </Typography>
            <Typography sx={{ fontSize: 16 }}>
              {t('cart.product_card.total_units')}: {data.quantity}
            </Typography>
          </Stack>
        </Grid>

        {/* <Grid item xs={12} display={size === 'small' ? 'none' : 'flex'}>
          <Stack direction="row" spacing={1} sx={{ pt: 1 }}>
            <Chip label={t('cart.product_card.status.in_stock')} sx={{ borderRadius: 0, background: '#F0F4F8', color: '#383838' }} />
          </Stack>
        </Grid> */}
      </Grid>
    </Grid>
  )
})

export { B2BCartItem }
