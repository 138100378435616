import { Box, FormControl, MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Pagination } from './Pagination'
import { Typography } from './Typography'

type CustomPaginationProps = {
  perPage: number
  page: number
  totalCount: number
  unit?: string
  onPageChange: (p: number) => void
  onPerPageChange: (p: number) => void
}

export const CustomPagination = ({ totalCount, page, perPage, unit, onPageChange, onPerPageChange }: CustomPaginationProps) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" flexDirection={{ xs: 'column', sm: 'row' }}>
      <Box
        display="flex"
        alignItems="center"
        sx={{ gap: { xs: 2, sm: 3 }, py: 0.25 }}
        flexDirection={{ xs: 'column', sm: 'row' }}
        mb={{ xs: 2, sm: 0 }}
      >
        <FormControl>
          <Select
            color="secondary"
            value={perPage.toString()}
            sx={{
              '& fieldset': {
                border: `1px solid ${theme.palette.common.gray700}`,
                borderRadius: 1,
              },
              '.MuiOutlinedInput-input': {
                background: theme.palette.common.white,
                color: theme.palette.common.gray300,
                fontSize: 16,
                lineHeight: '24px',
              },
            }}
            onChange={(e: SelectChangeEvent) => onPerPageChange(+e.target.value)}
          >
            {/* setting this to multiples of 4 or 3 as the number of columns
            being displayed ranges from 1 to 4 columns depending on the viewport */}
            <MenuItem value={12} sx={{ fontSize: 16, lineHeight: '19px' }}>
              12 {t('items_per_page')}
            </MenuItem>
            <MenuItem value={24} sx={{ fontSize: 16, lineHeight: '19px' }}>
              24 {t('items_per_page')}
            </MenuItem>
            <MenuItem value={48} sx={{ fontSize: 16, lineHeight: '19px' }}>
              48 {t('items_per_page')}
            </MenuItem>
            <MenuItem value={100} sx={{ fontSize: 16, lineHeight: '19px' }}>
              100 {t('items_per_page')}
            </MenuItem>
          </Select>
        </FormControl>
        <Typography
          sx={{
            textAlign: { xs: 'center', sm: 'left' },
            flexGrow: 1,
            fontSize: 16,
            fontWeight: '19px',
            color: theme.palette.common.gray300,
          }}
        >
          {'  '}
          {t('pagination.showing')} {Math.min(totalCount, (page - 1) * perPage + 1)} {''}
          {t('pagination.to')} {Math.min(totalCount, page * perPage)} {''}
          {t('pagination.of')} {totalCount} {''}
          {unit ?? t('pagination.orders')}
        </Typography>
      </Box>
      <Pagination
        currentPage={page}
        totalPages={Math.ceil(totalCount / perPage)}
        onSelectPage={onPageChange}
        onNext={() => onPageChange(Math.min(page + 1, Math.ceil(totalCount / perPage)))}
        onPrev={() => onPageChange(Math.max(page - 1, 1))}
      />
    </Box>
  )
}
