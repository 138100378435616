export const lightModeColors = {
  red: '#E21A22',
  darkRed: '#FC0000',
  lightRed: '#FFF0F0',
  white: '#FFFFFF',
  black: '#231F20',
  darkGreen: '#18AB56',
  lightGreen: '#F0FFF8',
  blue: '#0071BC',
  primaryBlue: '#0071BC',
  labelBgBlue: '#DBEFFC',
  softBlue: '#DAEEFD',
  darkBlue: '#00468B',
  lightBlue: '#589FEF',
  altBlue: '#0164A2',
  altLightBlue: '#EAF0F6',
  artboard: '#FDFEFF',
  text: '#303030',
  textSecondary: '#64748B',
  contrastBlue: '#0071BC',
  cyanBlue: '#0071BC',
  darkGray: '#4D4D4F',
  lightGray: '#F7F9FB',
  blue700: '#DBEFFC',
  blue900: '#F0F0FF',
  yellow: '#FFF3CD',
  lightYellow: '#fff3c8',
  darkYellow: '#856404',
  green700: '#DBF7EA',
  gray300: '#747474',
  gray600: '#CECECE',
  gray700: '#EEEEEE',
  qorpakGreen: '#00A69C',
  lightBg: '#F0F0FF',
  qorpakSecondary: '#548C89',
}

export const darkModeColors = {
  red: '#B00020',
  darkRed: '#B00020',
  lightRed: '#FF6464',
  white: '#212121',
  black: '#FFFFFF',
  darkGreen: '#F0FFF8',
  lightGreen: '#18AB56',
  blue: '#00468B',
  altBlue: '#0164A2',
  altLightBlue: '#EAF0F6',
  primaryBlue: '#0071BC',
  labelBgBlue: '#DBEFFC',
  qorpakGreen: '#00A69C',
  softBlue: '#DAEEFD',
  darkBlue: '#589FEF',
  lightBlue: '#0071BC',
  artboard: '#303030',
  text: '#303030',
  textSecondary: '#64748B',
  contrastBlue: '#589FEF',
  cyanBlue: '#0071BC',
  blue700: '#DBEFFC',
  blue900: '#F0F0FF',
  yellow: '#FFF3CD',
  lightYellow: '#FFF3C8',
  darkYellow: '#856404',
  green700: '#DBF7EA',
  lightGray: '#222323',
  darkGray: '#4D4D4F',
  gray300: '#F0F4F8',
  gray600: '#CECECE',
  gray700: '#616161',
  lightBg: '#F0F0FF',
  qorpakSecondary: '#548C89',
}

export const qorpakLightModeColors = {
  ...lightModeColors,
  blue: '#0079C1',
  primaryBlue: '#0079C1',
  qorpakGreen: '#00A69C',
  labelBgBlue: '#DBEFFC',
  lightBg: '#CCE0DF',
}

export const qorpakDarkModeColors = {
  ...darkModeColors,
  blue: '#0179C2',
  primaryBlue: '#0079C1',
  labelBgBlue: '#DBEFFC',
  black: '#231F20',
  qorpakGreen: '#00A69C',
  lightBg: '#CCE0DF',
}
